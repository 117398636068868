import { GridOptions } from '@ag-grid-community/all-modules';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { OAuthService } from 'angular-oauth2-oidc';
import { NgbStringAdapter } from '../common/convertors/ngbstringadapter';
import { AssignGateway } from '../common/models/assigngateway';
import { GatewayFilter } from '../common/models/gatewayfilter';
import { GatewayFirmware } from '../common/models/gatewayfirmware';
import { FCToasterService } from '../common/toaster/FCToasterService';
import { Service } from '../service';
import { GatewayDecommission } from './../common/models/gatewaydecommission';
import { unitSearchService } from '../common/unitSearchService';

@Component({
  selector: 'app-gateway',
  templateUrl: './gateway.component.html',
  styleUrls: ['./gateway.component.scss']
})
export class GatewayComponent implements OnInit {
  title = 'appBootstrap';
  @ViewChild("agGridGateway", { static: false }) agGridGateway: AgGridAngular;
  rowData: any[];
  columnDefs: any[];
  gatewayTypes: any[];
  gatewayTypeSelectedId: number;
  fmGatewayTypeSelectedId: number;
  gatewayTypeSelectedValue: any;
  countries: any[];
  txtGWSrchUnit: any = "";
  gridOptions: GridOptions;
  closeResult: string;
  isRowSelected: boolean = false;
  rowSelectedData: {};
  txtGWMLUnitNum: any;
  txtGWMlRemType: any;
  txtGWMlMacAdd: any;
  txtGWMlIccId: any;
  txtGWMlIccId2: any;
  GWFMVersion: any;
  txtGWFirmwareVersion: any;
  selectedGatewayType: any = {};
  gatewaySearchFilter = {} as GatewayFilter;
  date = new Date();
  assignGateway = {} as AssignGateway;
  lblErrorGWMLMacAdd = "";
  lblErrorGWFWVersionAdd = "";
  lblErrorGWMLType = "";
  selectedUnitsInfo = [];
  selectedUnits = [];
  gatewayDecommission = {} as GatewayDecommission;
  addGatewayFirmware = {} as GatewayFirmware;
  gatewayUpdateEnable: boolean = true;
  TxtGMfwVersion: any;
  isAdmin: boolean = false;
  txtGWPwd = "";
  lblVersionMessage = "";
  isBtnEndGateway: boolean = true;
  lblErrorGWPwd = "";
  txtSuggestedVersion = "";
  divTextColor: any;
  isSavedUpdated: boolean = false;
  Devicegateway: any;
  devicegatewayTypeSelId: number = 1;
  unitNumber: string;
  tempDataList: any[] = [];
  unitdeviceGatewayType: string;
  countryId: number;
  DefaultGatewayTypeText: string = "DIGI-EX12";
  DefaultGatewayTypeId: number = 3;
  DefaultMacAddress: string = "002704";
  isDoubleDeck: boolean = false;

  constructor(private apiService: Service, private modalService: NgbModal,
    private ngbStringAdapter: NgbStringAdapter, private toastr: FCToasterService,
    private oauthService: OAuthService, private unitSearch: unitSearchService) { }

  ngOnInit(): void {
    this.bindGridOnPageLoad();
    this.bindMasterDataFilters();
    this.bindDefaultValuesOnPageLoad();

    this.gridOptions = {
      defaultColDef: { filter: true },
      localeText: { noRowsToShow: 'No records found' },
      animateRows: true,
      pagination: true,
      paginationAutoPageSize: true
    }

    let claims = this.oauthService.getIdentityClaims();
    this.apiService
      .getUserDetails(claims["oid"])
      .subscribe((data: any) => {
        if (data != null) {
          this.isAdmin = this.apiService.isAdmin();
        }
      }, (error) => {
      });
  }

  //Grid Data bind in Commissioning page Load
  bindGridOnPageLoad() {
    this.columnDefs = [
      { headerName: "", field: "cbox", sortable: true, checkboxSelection: true, width: 50 },
      { headerName: "DIGI", field: "digiConnectionStatus", suppressFilter: true, sortable: false, width: 80, tooltipField: "digiConnectionStatus", headerTooltip: "DIGI", cellRenderer: params => { return this.bindDIGIStatus(params.value); } },
      { headerName: "Unit Number", field: "elevatorUnitNumber", sortable: true, width: 160, tooltipField: "elevatorUnitNumber", headerTooltip: "Unit Number" },
      { headerName: "Building Name", field: "buildingName", sortable: true, width: 220, tooltipField: "buildingName", headerTooltip: "Building Name" },
      { headerName: "Gateway Mac Address", field: "gatewayMacAddress", sortable: true, width: 230, resizable: true, tooltipField: "gatewayMacAddress", headerTooltip: "Gateway Mac Address" },
      { headerName: "Building Address", field: "buildingAddress", sortable: true, width: 300, resizable: true, tooltipField: "buildingAddress", headerTooltip: "Building Address" },
      { headerName: "CARLET", field: "carlet", sortable: true, width: 150, resizable: true, tooltipField: "CARLET", headerTooltip: "CARLET" },
      { headerName: "CP-ID/UUID", field: "cpidUuid", sortable: true, width: 200, resizable: true, tooltipField: "cpidUuid", headerTooltip: "CP-ID/UUID" },
      { headerName: "CP-ID/UUID2", field: "cpidUuid2", sortable: true, width: 200, resizable: true, tooltipField: "cpidUuid2", headerTooltip: "CP-ID/UUID2" },
      { headerName: "Country Id", field: "countryId", sortable: true, width: 150, tooltipField: "countryId", headerTooltip: "Country Id", hide: "true" },
      { headerName: "Country", field: "countryName", sortable: true, width: 200, tooltipField: "countryName", headerTooltip: "Country Name" },
      { headerName: "SIM Card ICCID1", field: "gatewayIccId", sortable: true, width: 220, resizable: true, tooltipField: "gatewayIccId", headerTooltip: "SIM Card ICC Id1" },
      { headerName: "SIM Card ICCID2", field: "gatewayIccId2", sortable: true, width: 220, resizable: true, tooltipField: "gatewayIccId2", headerTooltip: "SIM Card ICC Id2" },
      { headerName: "Gateway Type", field: "gatewayType", sortable: true, width: 180, tooltipField: "gatewayType", headerTooltip: "Gateway Type" },
      { headerName: "RemType Id", field: "remTypeId", sortable: true, width: 150, tooltipField: "remTypeId", headerTooltip: "Rem Type" }
    ];
  }

  bindDIGIStatus(statusDesc) {
    return statusDesc === "Online" ? "<i class='fa fa-circle cGreen'></i>" :
      statusDesc === "Offline" ? "<i class='fa fa-circle cRed'></i>" : "";
  }
  //Bind master data in filters on page load
  bindMasterDataFilters() {
    this.getGatewayTypes();
    this.getGatewayFirmwareVersion();
  }

  // Assign default values to variables on Page load
  bindDefaultValuesOnPageLoad() {
    this.txtGWMLUnitNum = "";
    this.txtGWMlRemType = "";
    this.txtGWMlMacAdd = this.DefaultMacAddress;
    this.txtGWMlIccId = "";
    this.txtGWMlIccId2 = "";
    this.gatewayTypeSelectedValue = this.DefaultGatewayTypeText;
    this.gatewayTypeSelectedId = this.DefaultGatewayTypeId;
    this.rowData = [];
    this.rowSelectedData = {};
    this.selectedUnits = [];
    this.isRowSelected = false;
    this.selectedUnitsInfo = [];
    this.gatewayUpdateEnable = true;
    this.isDoubleDeck = false;
  }

  getGridDataByFilterSearch() {
    this.bindDefaultValuesOnPageLoad();
    this.gatewaySearchFilter.searchField = (this.txtGWSrchUnit !== null && this.txtGWSrchUnit !== undefined) ? this.txtGWSrchUnit.trim() : "";
    if (this.txtGWSrchUnit != null && this.txtGWSrchUnit !== "") {
      this.gatewaySearchFilter.searchField = this.unitSearch.getUpdatedUnitSearch(this.txtGWSrchUnit);
      this.apiService
        .getUnitsByFilterSearch(this.gatewaySearchFilter)
        .subscribe((data: any) => {
          if (data != null) {
            this.rowData = data;
          }
          else {
            this.rowData = [];
          }
        });
    }
    else {
      this.toastr.showError("Please enter the Unit Number or Building Name or Address");
    }
    this.isSavedUpdated = false;
  }

  //Gateway Modal popup open
  open(content) {
    this.devicegatewayTypeSelId = 1; this.unitdeviceGatewayType = 'CPIB2';
    if (content._declarationTContainer.localNames[0] == 'decommModal') {
      this.getUnitDeviceGateway(false);
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
    else if (content._declarationTContainer.localNames[0] == 'gatewayModal' && !this.gatewayUpdateEnable) {
      this.unitdeviceGatewayType = "";
      this.getUnitDeviceGateway(true); this.checkDigiFirmwareVersion(false);
      if (!this.gatewayUpdateEnable && this.rowSelectedData != "") { this.txtGWMlMacAdd = this.rowSelectedData[0]["gatewayMacAddress"] }
      this.modalService.open(content, { windowClass: "myCustomModalClass", ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
    else if (content._declarationTContainer.localNames[0] == 'gatewayModal' && this.gatewayUpdateEnable) {
      this.modalService.open(content, { windowClass: "myCustomModalClass", ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
    else {
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  // Dismss Modal Popup
  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onKeyEnter() {
    this.getGridDataByFilterSearch();
  }

  // Close Modal Popup
  modalClose(isfirmware = false) {
    this.modalService.dismissAll();
    this.lblErrorGWMLMacAdd = "";
    this.lblErrorGWFWVersionAdd = "";
    this.lblErrorGWMLType = "";
    this.gatewayTypeSelectedValue = this.DefaultGatewayTypeText;
    this.gatewayTypeSelectedId = this.DefaultGatewayTypeId;
    this.txtGWMlMacAdd = this.DefaultMacAddress;
    this.txtGWMlIccId = "";
    this.txtGWMlIccId2 = "";
    this.TxtGMfwVersion = "";
    this.txtGWFirmwareVersion = "";
    this.getGatewayFirmwareVersionName(this.gatewayTypeSelectedId);
    this.txtGWPwd = "";
    this.lblVersionMessage = "";
    this.lblErrorGWPwd = "";
    this.txtSuggestedVersion = "";
    if (!isfirmware) { this.getGridDataByFilterSearch(); }
  }

  //Grid row selection event
  onRowSelected(event) {
    if (event.node.selected) {
      if (this.selectedUnitsInfo.length > 0 && event.node.data.buildingName !== this.selectedUnitsInfo[0]["buildingName"]) {
        this.toastr.showError("Selected units should be in same building");
      }
      else if (this.selectedUnitsInfo.length > 0 && event.node.data.gatewayMacAddress !== this.selectedUnitsInfo[0]["gatewayMacAddress"]) {
        this.toastr.showError("Selected units should have same gateway MacAddress");
      }
      else if (this.selectedUnitsInfo.length > 0 && event.node.data.gatewayIccId !== this.selectedUnitsInfo[0]["gatewayIccId"]) {
        this.toastr.showError("Selected units should have same SimcardIccId");
      }
      else {
        this.selectedUnitsInfo.push(event.node.data);
        this.selectedUnits.push(event.node.data.elevatorUnitNumber);
      }

      if (this.selectedUnitsInfo[0]["gatewayMacAddress"].length > 6) {
        this.gatewayUpdateEnable = false;
      }
      else {
        this.gatewayUpdateEnable = true;
      }
    }

    else {
      var index = this.selectedUnitsInfo.indexOf(event.node.data);
      if (index >= 0) {
        this.selectedUnitsInfo.splice(index, 1);
        this.selectedUnits.splice(index, 1);
      }
    }

    if (this.selectedUnitsInfo.length >= 1 && this.selectedUnitsInfo.length <= 4) {
      this.isRowSelected = true;
      this.rowSelectedData = this.selectedUnitsInfo;
      this.unitNumber = this.rowSelectedData[0].elevatorUnitNumber;
      this.countryId = this.rowSelectedData[0].countryId;
      this.GatewayModalLoad(this.rowSelectedData[0])
    }
    else {
      this.isRowSelected = false;
      this.rowSelectedData = {};
    }

    if (this.selectedUnitsInfo.length > 4) {
      this.toastr.showWarning("Please Select upto maximum 4 unit(s) to assign a Gateway");
    }
  }

  GatewayModalLoad(rowSelectedData) {
    this.isDoubleDeck = false;
    this.txtGWMlMacAdd = (rowSelectedData["gatewayMacAddress"] !== null && rowSelectedData["gatewayMacAddress"] !== undefined && rowSelectedData["gatewayMacAddress"] !== "") ? rowSelectedData["gatewayMacAddress"] : this.DefaultMacAddress;
    this.gatewayTypeSelectedValue = (rowSelectedData["gatewayType"] !== null && rowSelectedData["gatewayType"] !== undefined && rowSelectedData["gatewayType"] !== "") ? rowSelectedData["gatewayType"] : this.DefaultGatewayTypeText;
    if (this.gatewayTypeSelectedValue === "DIGI-WR21")
      this.gatewayTypeSelectedId = 2;
    else if (this.gatewayTypeSelectedValue === "DIGI-EX12")
      this.gatewayTypeSelectedId = 3;
    else if (this.gatewayTypeSelectedValue === "DIGI-EX15")
      this.gatewayTypeSelectedId = 4;
    else if (this.gatewayTypeSelectedValue === "BD")
      this.gatewayTypeSelectedId = 1;
    this.txtGWMlIccId = (rowSelectedData["gatewayIccId"] !== null && rowSelectedData["gatewayIccId"] !== undefined) ? rowSelectedData["gatewayIccId"] : "";
    this.txtGWMlIccId2 = (rowSelectedData["gatewayIccId2"] !== null && rowSelectedData["gatewayIccId2"] !== undefined) ? rowSelectedData["gatewayIccId2"] : "";
    if (rowSelectedData["cpidUuid2"] !== null && rowSelectedData["cpidUuid2"] !== undefined && rowSelectedData["cpidUuid2"] !== "")
      this.isDoubleDeck = true;
    this.getGatewayFirmwareVersionName(this.gatewayTypeSelectedId);
  }

  //Gateway Type drop down seletion changed in modal pop up
  gatewayTypeSelectChanged($event) {
    this.gatewayTypeSelectedId = $event.target.value;
    this.gatewayTypeSelectedValue = $event.target.options[$event.target.selectedIndex].text;
    this.gatewayTypeSelectedValue === "DIGI-WR21" ? this.txtGWMlMacAdd = "00042D" : (this.gatewayTypeSelectedValue === "DIGI-EX12" ? this.txtGWMlMacAdd = "002704" : (this.gatewayTypeSelectedValue === "DIGI-EX15" ? this.txtGWMlMacAdd = "002704" : this.txtGWMlMacAdd = ""));
    this.TxtGMfwVersion = "";
    this.getGatewayFirmwareVersionName(Number(this.gatewayTypeSelectedId));
  }

  fnBindBuildingdatabyUnitNumber() {
    this.tempDataList = [];
    this.selectedUnits = [];
    this.apiService
      .getBuildingUnits(this.unitNumber)
      .subscribe((data: any) => {
        for (var val of data.units) {
          this.selectedUnits.push(val['unitNumber']);
          this.tempDataList.push({ "elevatorUnitNumber": val['unitNumber'], "buildingName": data.buildingName, "cpidUuid": val['cpidUuid'], "countryId": this.countryId, "gatewayMacAddress": data.gatewayMacAddress });
        }
        this.rowSelectedData = this.tempDataList;
      });
  }
  //Gateway Firmware Type drop down seletion changed in modal pop up
  fmGatewayTypeSelectChanged($event) {
    this.gatewayTypeSelectedId = $event.target.value;
    this.gatewayTypeSelectedValue = $event.target.options[$event.target.selectedIndex].text;
    (this.gatewayTypeSelectedValue === "DIGI-WR21") ? this.txtGWMlMacAdd = "00042D" : this.txtGWMlMacAdd = ""
  }

  //Reset filters
  resetFilters() {
    this.bindDefaultValuesOnPageLoad();
    this.bindGridOnPageLoad();
    this.txtGWSrchUnit = "";
  }

  resetAfterGatewayAssign() {
    this.modalClose();
    this.bindGridOnPageLoad();
    this.getGridDataByFilterSearch();
    this.rowSelectedData = {};
    this.selectedUnitsInfo = [];
    this.isRowSelected = false;
    this.gatewayUpdateEnable = true;
  }

  checkDigiFirmwareVersion(closePopup) {
    this.apiService
      .getDigiFirmwareVersion(this.txtGWMlMacAdd)
      .subscribe((data: any) => {
        this.txtSuggestedVersion = data;
        this.DisplayValidMessages();
        if (data == '' || data == null) {
          setTimeout(() => {
            this.lblVersionMessage = "Firmware version not reported to DRM. Make sure Digi is online. Click on Get Firmware Version to check DRM status.";
            this.isBtnEndGateway = false;
          }, 5000);
        }
        else if (data == this.TxtGMfwVersion) {
          this.lblVersionMessage = "This Gateway has compatible firmware listed in DRM.";
          this.isBtnEndGateway = true;
          setTimeout(() => {
            if (closePopup) { this.resetAfterGatewayAssign(); }
          }, 5000);
        }
        else {
          setTimeout(() => {
            this.lblVersionMessage = "Running Firmware Version from DRM & Otis ONE Compatible Firmware versions are different. Manually update firmware if needed. Click on Get Firmware Version to check Gateway reported version to DRM.";
            this.isBtnEndGateway = false;
          }, 5000);
        }
      });
  }

  onUpdateFirmware($event) {
    this.checkDigiFirmwareVersion(false);
  }
  DisplayValidMessages() {
    if (this.txtSuggestedVersion == "" || this.txtSuggestedVersion == null) {
      this.toastr.showInformation("Firmware version not reported to DRM. Make sure Digi is online.");
    }
    else {
      this.toastr.showSuccess("Running Firmware Version from DRM Updated successfully !!");
    }
  }
  //Gateway Assign Button click event in Modal Popup
  onAssignGateway($event) {
    if ($event != null) {
      if (this.assignGatewayValidations()) {
        this.assignGateway.elevatorUnitNumber = this.selectedUnits;
        this.assignGateway.gatewayType = this.gatewayTypeSelectedValue;
        this.assignGateway.gatewayMacAddress = this.txtGWMlMacAdd;
        this.assignGateway.simcardIccId = this.txtGWMlIccId;
        this.assignGateway.simcardIccId2 = this.txtGWMlIccId2;
        this.assignGateway.buildingName = this.rowSelectedData[0]["buildingName"];
        this.assignGateway.buildingAddress = this.rowSelectedData[0]["buildingAddress"];
        this.assignGateway.communicationType = "";
        this.assignGateway.countryId = this.rowSelectedData[0]["countryId"];
        this.assignGateway.password = this.txtGWPwd;
        this.assignGateway.createdBy = "FCAdmin";
        this.assignGateway.createdDate = this.date.toDateString();
        this.assignGateway.deviceGatewayTypeId = this.devicegatewayTypeSelId;

        this.apiService
          .assignGateway(this.assignGateway)
          .subscribe((data: any) => {
            this.rowData = data;
            if (data == true) {
              this.toastr.showSuccess("Gateway assigned successfully !!");
              this.isSavedUpdated = true;
              this.gatewayUpdateEnable = false;
              setTimeout(() => {
                this.checkDigiFirmwareVersion(true);
              }, 10000);
              this.getUnitDeviceGateway(true);
            }
            else {
              this.toastr.showError("Gateway Assign Failed !!");
            }
          });
      }
    }
    else {
      this.toastr.showError("Please select unassigned gateway unit");
    }
  }

  assignGatewayValidations(): boolean {
    this.txtGWMlMacAdd = this.txtGWMlMacAdd.replace(/\:/g, "").trim();

    if (this.txtGWMlMacAdd.length !== 12) {
      this.lblErrorGWMLMacAdd = "Gateway Mac Address should be 12 characters length";
      return false;
    }
    else {
      this.lblErrorGWMLMacAdd = "";
    }

    return true;
  }

  //Get gateway types
  getGatewayTypes() {
    this.apiService
      .getGatewayType()
      .subscribe((data: any) => {
        this.gatewayTypes = data;
      });
  }

  //Get Country data
  getCountries() {
    this.apiService
      .getCountries()
      .subscribe((data: any) => {
        this.countries = data;
      });
  }

  //Get the gateway firmware version
  getGatewayFirmwareVersion() {
    this.apiService
      .getGatewayFirmwareVersion()
      .subscribe((data: any) => {
        this.GWFMVersion = data;
        this.gatewayTypeSelectedId = this.DefaultGatewayTypeId;
        const emptyVersionData = this.getEmptyGatewayFirmwareVersion(this.gatewayTypes, data);
        if (emptyVersionData.length > 0) {
          emptyVersionData.forEach(element => {
            this.GWFMVersion.push({
              gatewayTypeId: element.id,
              name: element.name,
              firmwareVersion: "N/A",
              createdBy: "N/A"
            })
          });
        }
        this.getGatewayFirmwareVersionName(this.gatewayTypeSelectedId);
      });
  }

  getUnitDeviceGateway(isgateway) {
    this.apiService
      .getUnitDeviceGatewayType(this.unitNumber)
      .subscribe((data: string) => {
        this.unitdeviceGatewayType = data;
        if (this.unitdeviceGatewayType != "CPIB2" && isgateway) { this.fnBindBuildingdatabyUnitNumber(); }
      });
  }

  clearErrorMessages() {
    this.lblErrorGWMLMacAdd = "";
    this.lblErrorGWMLType = "";
    this.lblErrorGWFWVersionAdd = "";
  }

  OnDecommissioinGateway() {
    if (this.rowSelectedData[0]["gatewayMacAddress"] !== "" && this.rowSelectedData[0]["gatewayMacAddress"] !== null && this.rowSelectedData[0]["gatewayMacAddress"] !== undefined) {
      this.gatewayDecommission.elevatorUnitNumber = this.rowSelectedData[0]["elevatorUnitNumber"];
      this.gatewayDecommission.gatewayMacAddress = this.rowSelectedData[0]["gatewayMacAddress"];
      this.gatewayDecommission.gatewayType = this.rowSelectedData[0]["gatewayType"];
      this.gatewayDecommission.countryId = this.rowSelectedData[0]["countryId"];
      this.gatewayDecommission.simICCID = this.rowSelectedData[0]["gatewayIccId"];
      this.gatewayDecommission.simICCID2 = this.rowSelectedData[0]["gatewayIccId2"];
      this.gatewayDecommission.unitDeviceGateway = this.unitdeviceGatewayType;

      this.apiService
        .gatewayDeCommission(this.gatewayDecommission)
        .subscribe((data: any) => {
          if (data == true) {
            if (this.unitdeviceGatewayType.toUpperCase() == "CPIB2") {
              this.toastr.showSuccess("Gateway decommissioned for Unit !!");
            }
            else {
              this.toastr.showSuccess("Gateway decommissioned successfully for the Building !!");
            }
            this.isSavedUpdated = true;
            this.modalClose();
            this.bindGridOnPageLoad();
            this.isRowSelected = false;
            this.rowSelectedData = {};
            this.selectedUnitsInfo = [];
          }
          else {
            this.toastr.showError("Decommission Failed !!");
          }
        });
    }
    else {
      this.toastr.showError("Please select the Gateway commissioned unit !!");
    }
  }

  gatewayFirmwareSubmit() {
    if (this.txtGWFirmwareVersion !== undefined)
      this.txtGWFirmwareVersion = this.txtGWFirmwareVersion.replace(/^\s+|\s+$/g, "");
    else
      this.lblErrorGWFWVersionAdd = "Please provide Gateway Firmware version";

    if (!!this.txtGWFirmwareVersion) {
      this.addGatewayFirmware.FirmwareVersion = this.txtGWFirmwareVersion;
      this.addGatewayFirmware.GatewayTypeId = this.gatewayTypeSelectedId;
      this.addGatewayFirmware.IsActive = true;
      this.apiService
        .addGatewayFirmware(this.addGatewayFirmware)
        .subscribe((data: any) => {
          if (data == true) {
            this.toastr.showSuccess("Gateway Firmware version saved successfully!!");
            this.isSavedUpdated = true;
            this.getGatewayFirmwareVersion();
            this.modalService.dismissAll();
            this.txtGWFirmwareVersion = "";
          }
          else {
            this.toastr.showError("Gateway Firmware version saved failed !!");
          }
        });
    } else {
      this.lblErrorGWFWVersionAdd = "Please provide Gateway Firmware version";
    }
  }

  getEmptyGatewayFirmwareVersion(gatewaytype, data) {
    let res = [];
    if (gatewaytype != undefined) {
      res = gatewaytype.filter(el => {
        return !data.find(element => {
          return element.gatewayTypeId === el.id;
        });
      });
      return res;
    }
  }

  getGatewayFirmwareVersionName(gatewayType) {
    this.GWFMVersion.forEach(element => {
      if (element.gatewayTypeId === gatewayType) {
        this.TxtGMfwVersion = element.firmwareVersion;
        return true;
      }
    });
  }
}
