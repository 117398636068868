import { EpalUnitDetails } from './../common/models/epalunitdetails';
import { EpalCommissionFilter } from './../common/models/epalcommissionfilter';
import { GridOptions } from '@ag-grid-community/all-modules';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { FCToasterService } from '../common/toaster/FCToasterService';
import { Service } from '../service';
import { EpalCommission } from '../common/models/epalcommission';
import { EpalDecomAndComm } from '../common/models/EpalDecomAndComm';
import { EpalRebootRequest } from '../common/models/epalRebootRequest';
import { AddContentToDevice } from '../common/models/addcontenttodevice';
import { EpalGatewayDecommission } from '../common/models/epalgatewaydecommission';
import { AssignEpalGateway } from '../common/models/assignepalgateway';
import { unitSearchService } from '../common/unitSearchService';
import { DraggableModalComponent } from '../common/popupmodel/draggable-modal.component';
import { ModalConfig } from '../common/modal-config';

@Component({
  selector: 'app-epalcommission',
  templateUrl: './epalcommission.component.html',
  styleUrls: ['./epalcommission.component.scss']
})
export class EpalcommissionComponent implements OnInit {

  @ViewChild("agGridCommission", { static: false }) agGridCommission: AgGridAngular;
  closeResult: string;
  rowData: any[];
  columnDefs: any[];
  gridOptions: GridOptions;
  unitNumbers: any[];
  selectedUnits = [];
  ePALCommissionedGateways: any[];
  ePALBuildingDetails: EpalUnitDetails;
  searchFilter = {} as EpalCommissionFilter;
  txtePALSrchBldId: any;
  txtePALSrchBldName: any;
  txtePALSrchBldAdd: any;
  txtePALSrchUnitNum: any;
  isRowSelected: boolean = false;
  rowSelectedData: {};
  selectedBuilding = [];
  lblMdlBldId: any;
  lblMdlBldName: any;
  txtMLePALHardwareId: any;
  lblComErrorePALHardwareId: string;
  lblMLEloviewSerialNum: string;
  lblDeComErrorePALHardwareId: string;
  lblComErrorTestBldId: string;
  ePALcommission = {} as EpalCommission;
  ePALDecomAndComm = {} as EpalDecomAndComm;
  ePALDeviceReboot = {} as EpalRebootRequest;
  ePALAddContentToDevice = {} as AddContentToDevice;
  ePALGatewayDecommission = {} as EpalGatewayDecommission;
  txtEpalHardwareId: string;
  deComEpalHwId: string;
  txtMdlePALTestBldId: any;
  txtMLEloviewSerialNum: any;
  isBtnStartEpal: boolean = true;
  isBtnEndEpal: boolean = false;
  appContent: any[];
  appContentSelectedId: number;
  appContentSelectedValue: string;
  appContentError: string = "";
  eloViewDeviceError: string = "";
  Devicegateway: any[];
  devicegatewayTypeSelId: number = 2;
  gatewayTypes: any[];
  gatewayTypeSelectedId: number;
  gatewayTypeSelectedValue: any;
  txtGWMlMacAdd: any;
  txtGWMlIccId: string;
  txtGWPwd: string;
  lblErrorGWMLMacAdd = "";
  assignEpalGateway = {} as AssignEpalGateway;
  defaultGatewayTypeText: string = "DIGI-EX12";
  defaultGatewayTypeId: number = 3;
  defaultMacAddress: string = "002704";
  TxtECfwVersion: any;
  txtGWFirmwareVersion: any;
  txtECSuggestedVersion = "";
  lblVersionMessage = "";
  isVersionMatch: boolean = true;
  GWFMVersion: any;
  gatewayUpdateEnabled: boolean = false;
  txtEloViewDevice: any;
  temptxtEloViewDevice: any;
  eloViewDeviceID: any;
  eloviewDeviceStatus: boolean = null;
  strUnitNumbers : string = "";
  lblNewDeviceName: string = "";
  modalRef: any;
  modalOptions: NgbModalOptions = ModalConfig;
  
  constructor(private apiService: Service, private modalService: NgbModal,
    private toastr: FCToasterService, private unitSearch: unitSearchService) { }

  ngOnInit(): void {
    this.bindDefaultValuesOnLoad();
    this.bindGridOnPageLoad();

    this.gridOptions = {
      defaultColDef: { filter: true },
      localeText: { noRowsToShow: 'No records found' },
      animateRows: true,
      pagination: true,
      paginationAutoPageSize: true
    }
    
    let modalContent: any = $('.modal-content');
    let modalHeader = $('.modal-headera');
    modalHeader.addClass('cursor-all-scroll');
    modalContent.draggable({
      handle: '.modal-header'
    });

  }
  
  openModal() {
    this.modalRef = this.modalService.open(DraggableModalComponent, this.modalOptions);
    this.modalRef.componentInstance.title = 'Drag Me!';
    this.modalRef.componentInstance.message = 'Try dragging this modal around using the modal header!';
    this.modalRef.componentInstance.onSubmitSubject
      .subscribe((res: boolean) => {
        
      });
  }
  
  bindDefaultValuesOnLoad() {
    this.txtePALSrchBldId = "";
    this.txtePALSrchBldName = "";
    this.txtePALSrchBldAdd = "";
    this.txtePALSrchUnitNum = "";
    this.rowSelectedData = {};
    this.isRowSelected = false;
    this.lblMdlBldId = "";
    this.lblMdlBldName = "";
    this.txtMLePALHardwareId = "";
    this.selectedBuilding = [];
    this.rowData = [];
    this.lblComErrorePALHardwareId = "";
    this.lblDeComErrorePALHardwareId = "";
    this.lblComErrorTestBldId = "";
    this.txtEpalHardwareId = "";
    this.deComEpalHwId = "";
    this.ePALBuildingDetails = new EpalUnitDetails();
    this.txtMdlePALTestBldId = "";
    this.txtMLEloviewSerialNum = "";
    this.isBtnStartEpal = true;
    this.isBtnEndEpal = false;
    this.appContentSelectedId = 0;
    this.appContentSelectedValue = "";
    this.appContentError = "";
    this.eloViewDeviceError = "";
    this.gatewayTypeSelectedValue = this.defaultGatewayTypeText;
    this.gatewayTypeSelectedId = this.defaultGatewayTypeId;
    this.txtGWMlMacAdd = this.defaultMacAddress;
    this.txtGWMlIccId = "";
    this.txtGWPwd = "";
    this.lblVersionMessage = "";
    this.txtEloViewDevice = "";
    this.temptxtEloViewDevice = "";
    this.eloViewDeviceID = "";
    this.getDeviceGatewayTypes();
    this.getGatewayTypes();
    this.getGatewayFirmwareVersion();
    this.eloviewDeviceStatus = null;
    this.strUnitNumbers = "";
    this.lblNewDeviceName = "";
  }

  getGatewayFirmwareVersion() {
    this.apiService
      .getGatewayFirmwareVersion()
      .subscribe((data: any) => {
        this.GWFMVersion = data;
        this.gatewayTypeSelectedId = this.defaultGatewayTypeId;
        const emptyVersionData = this.getEmptyGatewayFirmwareVersion(this.gatewayTypes, data);
        emptyVersionData.forEach(element => {
          this.GWFMVersion.push({
            gatewayTypeId: element.id,
            name: element.name,
            firmwareVersion: "N/A",
            createdBy: "N/A"
          })
        });
        this.getGatewayFirmwareVersionName(Number(this.gatewayTypeSelectedId));
      });
  }

  getEmptyGatewayFirmwareVersion(gatewaytype, data) {
    let res = [];
    if (gatewaytype != undefined) {
      res = gatewaytype.filter(el => {
        return !data.find(element => {
          return element.gatewayTypeId === el.id;
        });
      });
      return res;
    }
  }

  getGatewayFirmwareVersionName(gatewayType) {
    this.GWFMVersion.forEach(element => {
      if (element.gatewayTypeId === gatewayType) {
        this.TxtECfwVersion = element.firmwareVersion;
        return true;
      }
    });
  }

  ClearValuesOnsave() {
    this.devicegatewayTypeSelId = 2;
    this.gatewayTypeSelectedId = this.defaultGatewayTypeId;
    this.getGatewayFirmwareVersionName(Number(this.gatewayTypeSelectedId));
    this.txtGWMlMacAdd = this.defaultMacAddress;
    this.txtGWMlIccId = "";
    this.txtGWPwd = "";
    this.lblErrorGWMLMacAdd = "";
    this.gatewayUpdateEnabled = false;
    this.lblVersionMessage = "";
    this.txtECSuggestedVersion = "";
  }

  bindGridOnPageLoad() {
    this.columnDefs = [
      { headerName: "", field: "cbox", checkboxSelection: true, width: 70 },
      { headerName: "Building Id", field: "buildingId", sortable: true, width: 250, tooltipField: "buildingId", headerTooltip: "Building Id" },
      { headerName: "Building Name", field: "buildingName", sortable: true, width: 300, tooltipField: "buildingName", headerTooltip: "Building Name" },
      { headerName: "Building Address", field: "buildingAddress", sortable: true, width: 400, resizable: true, tooltipField: "buildingAddress", headerTooltip: "Building Address" },
      { headerName: "Building City", field: "buildingCity", sortable: true, width: 250, resizable: true, tooltipField: "buildingCity", headerTooltip: "Building City" },
    ];
    this.getGridDataByFilterSearch();
    this.getEloViewAppContent();
    this.getDeviceGatewayTypes();
    this.getGatewayTypes();
  }

  getGridDataByFilterSearch() {
    this.searchFilter.buildingId = (this.txtePALSrchBldId !== null && this.txtePALSrchBldId !== undefined) ? this.txtePALSrchBldId.trim() : "";
    this.searchFilter.buildingName = (this.txtePALSrchBldName !== null && this.txtePALSrchBldName !== undefined) ? this.txtePALSrchBldName.trim() : "";
    this.searchFilter.buildingAddress = (this.txtePALSrchBldAdd !== null && this.txtePALSrchBldAdd !== undefined) ? this.txtePALSrchBldAdd.trim() : "";
    if (this.txtePALSrchUnitNum !== null && this.txtePALSrchUnitNum !== undefined) {
      this.searchFilter.elevatorUnitNumber = this.unitSearch.getUpdatedUnitSearch(this.txtePALSrchUnitNum);
    }
    else {
      this.searchFilter.elevatorUnitNumber = "";
    }
    this.isRowSelected = false;
    this.rowSelectedData = {};
    this.selectedBuilding = [];

    this.apiService
      .getBuildingDetailsByFilterSearch(this.searchFilter)
      .subscribe((data: any) => {
        if (data != null) {
          this.rowData = [];
          this.rowData = data;
        }
        else {
          this.rowData = [];
        }
      });
  }

  resetFilters(event) {
    this.bindDefaultValuesOnLoad();
    this.bindGridOnPageLoad();
  }

  getEloViewAppContent() {
    this.apiService
      .getEloViewAppContent()
      .subscribe((data: any) => {
        this.appContent = data;
      });
  }

  getEloViewDevices() {
    this.apiService
      .getEloViewDevices(this.txtMLEloviewSerialNum.trim(), this.lblMdlBldName.trim())
      .subscribe((data: any) => {
        if(data != null) {
          this.temptxtEloViewDevice = data.deviceName;
          this.txtEloViewDevice= data.deviceName + ' - ' + data.hardwareId;
          this.eloViewDeviceID= data.deviceId;
          this.eloviewDeviceStatus = data.deviceStatus;
          this.eloViewDeviceError = "";
        }
        else {
          this.temptxtEloViewDevice = "";
          this.txtEloViewDevice = "";
          this.eloViewDeviceID = "";
        }
       
      });
  }

  onContentSelectChanged($event) {
    this.appContentSelectedId = $event.target.value;
    this.appContentSelectedValue = $event.target.options[$event.target.selectedIndex].text;
    if (this.appContentSelectedId !== 0) {
      this.appContentError = "";
    }
  }

  //Grid row selection event
  onRowSelected(event) {
    this.strUnitNumbers = "";
    this.selectedUnits = [];
    if (event.node.selected) {
      this.selectedBuilding.push(event.node.data);
    }
    else {
      var index = this.selectedBuilding.indexOf(event.node.data);
      this.selectedBuilding.splice(index, 1);
    }

    if (this.selectedBuilding.length === 1) {
      this.isRowSelected = true;
      this.rowSelectedData = this.selectedBuilding[0];
      this.ePALCommModalLoad(this.rowSelectedData);
      this.ePALGatewayModalLoad(this.rowSelectedData);
      this.getGatewayFirmwareVersionName(Number(this.gatewayTypeSelectedId));
    }
    else {
      this.isRowSelected = false;
      this.rowSelectedData = {};
    }
  }

  ePALCommModalLoad(rowSelectedData) {
    this.lblMdlBldId = (rowSelectedData["buildingId"] !== null && rowSelectedData["buildingId"] !== undefined) ? rowSelectedData["buildingId"] : "";
    this.lblMdlBldName = (rowSelectedData["buildingName"] !== null && rowSelectedData["buildingName"] !== undefined) ? rowSelectedData["buildingName"] : "";

    if (this.lblMdlBldId !== null && this.lblMdlBldId !== "" && this.lblMdlBldId !== undefined) {
      this.apiService
        .getUnitDetailsByBuildingId(this.lblMdlBldId)
        .subscribe((data: any) => {
          if (data != null) {
            this.unitNumbers = data;
            for (var val of this.unitNumbers) {
              this.selectedUnits.push(val['elevatorUnitNumber']);
              this.strUnitNumbers = this.strUnitNumbers.length > 0 ? this.strUnitNumbers + ", " + val['elevatorUnitNumber'] : val['elevatorUnitNumber'];
            }
          }
          else {
            this.unitNumbers = [];
            this.selectedUnits = [];
          }
        });
    }
  }

  // Commission Modal Popup
  open(content) {
    this.lblComErrorePALHardwareId = "";
    this.lblDeComErrorePALHardwareId = "";
    this.lblComErrorTestBldId = "";
    this.ClearValuesOnsave();
    
    if (content._declarationTContainer.localNames[0] == 'UpdateEloViewDevice') {
      this.modalService.open(content, { windowClass: "eloUpdateStyle", ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
    else {
      this.modalService.open(content, { windowClass: "myCustomModalePALClass", ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false, centered: true }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  // Close Modal Popup
  getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // Close Modal Popup
  modalClose(content) {
    this.modalService.dismissAll();
    this.resetDeCommModal();
    this.resetCommModal();
    this.txtGWMlMacAdd = this.defaultMacAddress;
  }

  onKeyEnter() {
    this.getGridDataByFilterSearch();
  }

  //ePAL HwID Commission for Test Building Id
  onEpalCommForTestBld(isUpdateDevice) {
    if (this.onEpalCommValidation()) {
      if(isUpdateDevice)
      {
          if(this.txtEloViewDevice != "" )
          {
              this.lblNewDeviceName = this.appContentSelectedValue + ": " + this.lblMdlBldName + ": " + this.txtMLePALHardwareId + ": " + this.strUnitNumbers
              let lblctrl = document.getElementById('lblUpdDevice') as HTMLElement;
              lblctrl.click();
              return false;
          }
      }
      else
      { 
        let lblctrl = document.getElementById('btnClose') as HTMLElement;
        lblctrl.click();
      }
      this.lblComErrorePALHardwareId = "";
      this.ePALcommission.testBuildingId = this.txtMdlePALTestBldId.trim();
      this.ePALcommission.buildingId = this.lblMdlBldId;
      this.ePALcommission.epalHardwareId = this.txtMLePALHardwareId.trim();
      this.ePALcommission.deviceApplicationType = this.appContentSelectedValue.trim();
      this.ePALcommission.eloViewSerialNumber = this.txtMLEloviewSerialNum.trim();
      this.ePALcommission.isNewDevice = this.txtEloViewDevice != "" ? false : true;

      this.apiService
        .ePALCommissioning(this.ePALcommission)
        .subscribe((data: any) => {         
          if (data.trim().toLocaleLowerCase() === "success") {
            this.toastr.showSuccess("Successfully Commissioned ePAL Device with Test Building !!");
            this.isBtnStartEpal = false;
            this.isBtnEndEpal = true;
            this.getEloViewDevices();            
          }
          else {
            this.toastr.showError("ePAL Device Commission Failed !!");
            this.isBtnStartEpal = true;
            this.isBtnEndEpal = false;
          }
        });
    }
  }

  onEpalCommValidation(): boolean {
    if (this.txtMLePALHardwareId === null || this.txtMLePALHardwareId === undefined || this.txtMLePALHardwareId.trim() === "") {
      this.lblComErrorePALHardwareId = "Enter ePAL HardwareId";
      return false;
    }
    else {
      this.lblComErrorePALHardwareId = "";
    }

    if (this.txtMdlePALTestBldId === null || this.txtMdlePALTestBldId === undefined || this.txtMdlePALTestBldId.trim() === "") {
      this.lblComErrorTestBldId = "Enter Test Building Id";
      return false;
    }
    else {
      this.lblComErrorTestBldId = "";
    }

    if (this.txtMLEloviewSerialNum === null || this.txtMLEloviewSerialNum === undefined || this.txtMLEloviewSerialNum.trim() === "") {
      this.lblMLEloviewSerialNum = "Enter EloView Serial Number";
      return false;
    }
    else {
      this.lblMLEloviewSerialNum = "";
    }   

    if (this.appContentSelectedId.toString() === "0") {
      this.appContentError = "Select Device Application Type";
      return false;
    }
    else {
      this.appContentError = "";
    }    
    return true;
  }

  //ePAL HwID De-commission for Production Building Id

  onDecommissionePALHardwareId() {
    if (this.txtEpalHardwareId !== null && this.txtEpalHardwareId.trim() !== ""
      && this.txtEpalHardwareId !== undefined && this.ePALBuildingDetails.buildingId != undefined) {
      this.apiService
        .ePALDeCommissioning(this.deComEpalHwId, this.ePALBuildingDetails.buildingId)
        .subscribe((data: any) => {
          if (data == true) {
            this.toastr.showSuccess("Successfully Decommissioned !!");
            this.modalClose("modal close");
            this.getGridDataByFilterSearch();
            this.isBtnEndEpal = false;
          }
          else {
            this.toastr.showError("Decommission Failed !!");
          }
        });
    }
    else {
      if (this.txtEpalHardwareId !== null && this.txtEpalHardwareId.trim() === ""
        && this.txtEpalHardwareId !== undefined) { this.lblDeComErrorePALHardwareId = "Enter ePAL HardwareId"; }
      else if (this.ePALBuildingDetails.buildingId === undefined && this.txtEpalHardwareId.trim() !== "") { this.lblDeComErrorePALHardwareId = "Enter valid ePAL HardwareId"; }
    }
  }

  //ePAL HwID DeCommission for Test Building Id and Comm for Production Building Id
  onEPALCommAndDeComm() {
    this.ePALDecomAndComm.buildingId = this.lblMdlBldId;
    this.ePALDecomAndComm.testBuildingId = this.txtMdlePALTestBldId.trim();
    this.ePALDecomAndComm.epalHardwareId = this.txtMLePALHardwareId.trim();
    this.ePALDecomAndComm.deviceApplicationType = this.appContentSelectedValue.trim();
    this.ePALDecomAndComm.eloViewSerialNumber = this.txtMLEloviewSerialNum.trim();

    this.apiService
      .ePALDeCommAndCommission(this.ePALDecomAndComm)
      .subscribe((data: string) => {
        if (data.trim().toLocaleLowerCase() === "success") {
          this.toastr.showSuccess("Successfully Commissioned ePAL Device With Production Building !!");         
         }
        else {
          this.toastr.showError(data);
        }
      });
  }

  getBuildingUnitsSearch() {    
    if (this.txtEpalHardwareId !== null && this.txtEpalHardwareId.trim() !== "" && this.txtEpalHardwareId !== undefined) {
      this.lblDeComErrorePALHardwareId = "";
      this.deComEpalHwId = this.txtEpalHardwareId;

      this.apiService
        .getBuildingUnitDetailsByePALHwId(this.txtEpalHardwareId)
        .subscribe((data: any) => {
          if (data != null) {
            this.ePALBuildingDetails.buildingId = data["buildingId"];
            this.ePALBuildingDetails.buildingName = data["buildingName"];
            this.ePALBuildingDetails.eloviewSerialNumber = data["eloviewSerialNumber"];            
            this.ePALBuildingDetails.Units = data["units"];
          }
          else {
            this.toastr.showInformation("No Records Found for a given ePAL HardwareId : " + this.txtEpalHardwareId);
            this.ePALBuildingDetails = new EpalUnitDetails();
          }
        });
    }
    else {
      this.lblDeComErrorePALHardwareId = "Enter ePAL HardwareId";
    }
  }

  resetDeCommModal() {
    this.lblDeComErrorePALHardwareId = "";
    this.txtEpalHardwareId = "";
    this.ePALBuildingDetails = new EpalUnitDetails();
  }

  resetCommModal() {
    this.txtMLePALHardwareId = "";
    this.txtMdlePALTestBldId = "";
    this.txtMLEloviewSerialNum = "";
    this.isBtnStartEpal = true;
    this.isBtnEndEpal = false;
    this.appContentSelectedId = 0;
    this.lblMLEloviewSerialNum = "";
    this.appContentError = "";
    this.eloViewDeviceError = "";
    this.txtEloViewDevice = "";
    this.temptxtEloViewDevice = "";
    this.eloViewDeviceID = "";
  }

  onInstallApkToDevice() {
    if (this.onInstallApkToDeviceValidation()) {
      this.ePALAddContentToDevice.deviceId = this.eloViewDeviceID;
      this.ePALAddContentToDevice.contentId = this.appContentSelectedId.toString();

      this.apiService
        .addContentToDevice(this.ePALAddContentToDevice)
        .subscribe((data: any) => {
          if (data == true) {
            this.toastr.showSuccess("APK Content Successfully installed into the Device!!");
          }
          else {
            this.toastr.showError("Content Installation Failed!!");
          }
        });
    }
  }

  onInstallApkToDeviceValidation(): boolean {
    if (this.txtMLEloviewSerialNum === null || this.txtMLEloviewSerialNum === undefined || this.txtMLEloviewSerialNum.trim() === "") {
      this.lblMLEloviewSerialNum = "Enter EloView Serial Number";
      return false;
    }
    else {
      this.lblMLEloviewSerialNum = "";
    } 
    if (this.appContentSelectedId.toString() === "0") {
      this.appContentError = "Select Device Application Type";
      return false;
    }
    else {
      this.appContentError = "";
    }
    if (this.txtEloViewDevice === null || this.txtEloViewDevice === undefined || this.txtEloViewDevice.trim() === "") {
      this.eloViewDeviceError = "Search EloView Device";
      return false;
    }
    else {
      this.eloViewDeviceError = "";
    }
    return true;
  }

  onDeviceReboot() {
    if (this.txtMLEloviewSerialNum !== null && this.txtMLEloviewSerialNum.trim() !== "" && this.txtMLEloviewSerialNum !== undefined) {
      this.ePALDeviceReboot.eloViewSerialNumber = this.txtMLEloviewSerialNum.trim();
      this.lblMLEloviewSerialNum = "";
      this.apiService
        .eloViewDeviceReboot(this.ePALDeviceReboot)
        .subscribe((data: any) => {
          if (data == true) {
            this.toastr.showSuccess("Reboot Successful !!");
          }
          else {
            this.toastr.showError("Reboot Failed !!");
          }
        });
    }
    else {
      this.lblMLEloviewSerialNum = "Enter EloView SerialNumber";
    }
  }

  gatewayTypeSelectChanged($event) {
    this.gatewayTypeSelectedId = $event.target.value;
    this.gatewayTypeSelectedValue = $event.target.options[$event.target.selectedIndex].text;
    this.txtGWMlMacAdd = "test";
    this.gatewayTypeSelectedValue === "DIGI-WR21" ? this.txtGWMlMacAdd = "00042D" : (this.gatewayTypeSelectedValue === "DIGI-EX12" ? this.txtGWMlMacAdd = "002704" : (this.gatewayTypeSelectedValue === "DIGI-EX15" ? this.txtGWMlMacAdd = "002704" : this.txtGWMlMacAdd = ""));
    this.getGatewayFirmwareVersionName(Number(this.gatewayTypeSelectedId));
  }
  clearErrorMessages() {

  }

  //get Device using Gateway
  getDeviceGatewayTypes() {
    this.apiService
      .getDeviceGateway()
      .subscribe((data: any) => {
        this.Devicegateway = data.slice(1);
      });
  }

  //Get gateway types
  getGatewayTypes() {
    this.apiService
      .getGatewayType()
      .subscribe((data: any) => {
        this.gatewayTypes = data.slice(1);
      });
  }

  AssignEpalGateway($event) {
    if (this.assignEpalGatewayValidations()) {
      this.assignEpalGateway.elevatorUnitNumber = this.selectedUnits;
      this.assignEpalGateway.gatewayTypeId = this.gatewayTypeSelectedId;
      this.assignEpalGateway.gatewayMacAddress = this.txtGWMlMacAdd;
      this.assignEpalGateway.simcardIccId = this.txtGWMlIccId;
      this.assignEpalGateway.buildingId = this.rowSelectedData["buildingId"];
      this.assignEpalGateway.buildingName = this.rowSelectedData["buildingName"];
      this.assignEpalGateway.password = this.txtGWPwd;
      this.assignEpalGateway.deviceGatewayTypeId = this.devicegatewayTypeSelId;

      this.apiService
        .ePALAssignGateway(this.assignEpalGateway)
        .subscribe((data: any) => {
          this.rowData = data;
          if (data == true) {
            this.toastr.showSuccess("ePAL Gateway assigned successfully !!");
            this.getEpalGatewayInfo(this.rowSelectedData);
            setTimeout(() => {
              this.checkDIGIFirmwareVersion();
            }, 10000);           
            this.gatewayUpdateEnabled = true;
          }
          else {
            this.toastr.showError("Gateway Assign Failed !!");
          }
        });
    }
  }

  assignEpalGatewayValidations(): boolean {
    this.txtGWMlMacAdd = this.txtGWMlMacAdd.replace(/\:/g, "").trim();

    if (this.txtGWMlMacAdd.length !== 12) {
      this.lblErrorGWMLMacAdd = "Gateway Mac Address should be 12 characters length";
      return false;
    }
    else {
      this.lblErrorGWMLMacAdd = "";
    }

    return true;
  }

  onEpalDecommission($event) {
    this.ePALGatewayDecommission.buildingId = $event["buildingId"];
    this.ePALGatewayDecommission.gatewayMacAddress = $event["gatewayMacAddress"];
    this.ePALGatewayDecommission.deviceGatewayTypeId = $event["deviceGatewayTypeId"];

    if (confirm("Are you sure want to to Decommission ePAL Gateway MacAddress: " + this.ePALGatewayDecommission.gatewayMacAddress + " for the BuildingId: " + this.ePALGatewayDecommission.buildingId)) {
      {
        this.apiService
          .ePALGatewayDeCommission(this.ePALGatewayDecommission)
          .subscribe((data: any) => {
            if (data == true) {
              this.toastr.showSuccess("Successfully Decommissioned ePAL Gateway MacAddress!!");
              this.getEpalGatewayInfo(this.rowSelectedData);
              this.ClearValuesOnsave();
            }
            else {
              this.toastr.showError("ePAL Gateway Decommission Failed !!");
            }
          });
      }
    }
  }

  //ePAl Gateway Modal
  ePALGatewayModalLoad(rowSelectedData) {
    this.getEpalGatewayInfo(rowSelectedData)
  }

  //Get ePAL Assigned Gateways Data
  getEpalGatewayInfo(rowSelectedData) {
    var lblePalMdlBldId = (rowSelectedData["buildingId"] !== null && rowSelectedData["buildingId"] !== undefined) ? rowSelectedData["buildingId"] : "";

    if (lblePalMdlBldId !== null && lblePalMdlBldId !== "" && lblePalMdlBldId !== undefined) {
      this.apiService
        .getEpalGatewayInfoByBuildingId(lblePalMdlBldId)
        .subscribe((data: any) => {
          if (data != null) {
            this.ePALCommissionedGateways = data;
          }
          else {
            this.ePALCommissionedGateways = [];
          }
        });
    }
  }

  onUpdateFirmware($event) {
    this.checkDIGIFirmwareVersion();
  }

  onReset($event) {
    this.ClearValuesOnsave();
    this.getGatewayFirmwareVersionName(Number(this.gatewayTypeSelectedId));
  }

  checkDIGIFirmwareVersion() {
    this.apiService
      .getDigiFirmwareVersion(this.txtGWMlMacAdd)
      .subscribe((data: any) => {
        this.txtECSuggestedVersion = data;
        this.DisplayValidMessages();
        if (data == '' || data == null) {
          setTimeout(() => {
            this.lblVersionMessage = "Firmware version not reported to DRM. Make sure Digi is online. Click on Get Firmware Version to check DRM status.";
            this.isVersionMatch = false;
          }, 5000);
        }
        else if (data == this.TxtECfwVersion) {
          this.lblVersionMessage = "This Gateway has compatible firmware listed in DRM.";
          this.isVersionMatch = true;
        }
        else {
          setTimeout(() => {
            this.lblVersionMessage = "Running Firmware Version from DRM & Otis ONE Compatible Firmware versions are different. Manually update firmware if needed. Click on Get Firmware Version to check Gateway reported version to DRM.";
            this.isVersionMatch = false;
          }, 5000);
        }
      });
  }

  DisplayValidMessages() {
    if (this.txtECSuggestedVersion == "" || this.txtECSuggestedVersion == null) {
      this.toastr.showInformation("Firmware version not reported to DRM. Make sure Digi is online.");
    }
    else {
      this.toastr.showSuccess("Running Firmware Version from DRM Updated successfully !!");
    }
  }

  onEpalDeviceSearch() {
    this.appContentError = "";
    if (this.txtMLEloviewSerialNum === null || this.txtMLEloviewSerialNum === undefined || this.txtMLEloviewSerialNum.trim() === "") {
      this.lblMLEloviewSerialNum = "Enter EloView Serial Number";      
      return false;
    }
    else {
      this.lblMLEloviewSerialNum = "";
      this.getEloViewDevices();      
    }   
  }
}
